<template>
  <div>
    <v-card max-width="1200" class="mx-auto my-5" elevation="0" color="transparent">
      
      <v-card class="elevation-0 gray-gradient my-4" dark height="200px">
        <v-container fill-height grid-list-md text-xs-center>
          <v-layout row wrap align-center text-center>
            <v-flex>
              <h3>Welcome to the Hounddog App</h3>
              <p>To begin, you can navigate to the "Sites" in the top menu and begin to add your sites.<br>You are one step closer to getting <b>zen</b>.</p>
            </v-flex>
            <v-flex>
              <lottie-animation
                ref="anim"
                :loop="true"
                :animationData="require('@/assets/zen.json')"
                style="width:180px;height:180px;margin: auto;"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>

      <v-container>
        <v-row>
          <v-col>
            <v-wp-security-news></v-wp-security-news>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  import LottieAnimation  from 'lottie-web-vue'
  export default {
    name: 'Home.vue',
    data() {
      return {
      }
    },
    components: {
      LottieAnimation
    },
    computed: {

    }
  }
</script>

<style lang="scss">
</style>